<template>
    <div class="personal section">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/personal.png" width="24px" height="24px" />
            <span>{{ $translate(section.title.toUpperCase()) }}</span>
        </h3>
        <div
            class="profile-item"
            v-for="(item, idx) in personal"
            :key="`personal-${idx}`"
            :class="{ required: $profile.required(item.key) }"
        >
            <template v-if="item.key === 'intro'">
                <div class="intro-wrapper w-100" @click="onClickProfileItem(item.key)">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div
                            class="label m-b-8"
                            style="width: fit-content !important"
                            :class="{ required: $profile.required(item.key) }"
                            v-html="$translate($case.toConst(item.key))"
                        />
                        <div class="flex-row">
                            <div class="pur-icon" v-if="item.pur">심사중</div>
                        </div>
                    </div>
                    <div
                        class="w-100 intro"
                        v-html="profileIntro || placeholderIntro"
                        :class="{
                            'c-black': profile.intro,
                            'c-grey': !profile.intro,
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <div
                    class="label"
                    :class="{ required: $profile.required(item.key) }"
                    v-html="$translate($case.toConst(item.key))"
                />
                <div class="item" @click="onClickProfileItem(item.key)">
                    <div class="flex-row">
                        <div
                            class="btn-selector hi"
                            :class="{
                                'c-primary': getVal(item.key) !== 'NO_VALUE',
                                'no-input': getVal(item.key) === 'NO_VALUE',
                            }"
                            v-html="$translate(getVal(item.key, item.pur))"
                        />
                        <img
                            v-if="item.key === 'age'"
                            class="m-r-2 lock"
                            :src="require(`@/assets/images/icons/lock.png`)"
                            width="16px"
                            height="16px"
                        />
                    </div>
                    <div class="pur-icon" v-if="item.pur">심사중</div>
                </div>
                <div class="go-detail">
                    <img
                        class="m-r-6"
                        :src="require(`@/assets/images/icons/arrow_right.svg`)"
                        width="16px"
                        height="16px"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Personal',
    props: ['section'],
    methods: {
        getVal(key) {
            if ([null, ''].includes(this.profile[key])) return 'NO_VALUE'

            if (key === 'state') {
                const prefix = key !== 'state' ? `${key}_` : ''
                if (!this.profile[`${prefix}state`]) return 'NO_VALUE'

                if (this.profile[`${prefix}station_name`]) {
                    return `${this.profile[`${prefix}station_name`]}`
                }
                if (this.profile[`${prefix}region_name`]) {
                    return `${this.profile[`${prefix}region_name`]}`
                }

                if ((this.profile[`${prefix}state`] || {}).is_station) {
                    return `${this.profile[`${prefix}state`].name} ${this.profile[`${prefix}station`].name}역`
                } else {
                    return `${this.profile[`${prefix}state`].name} ${this.profile[`${prefix}region`].district}`
                }
            }
            if (key === 'health_status') {
                return (this.$profile.healthStatus[this.profile[key]] || {}).title || 'NO_VALUE'
            }
            if (key === 'age') {
                return this.$options.filters.asAge(this.profile.birthday)
            }
            if (key === 'marry') {
                return this.profile.marry ? '재혼' : '초혼'
            }
            if (key === 'smoking') {
                return this.profile.smoking ? '흡연' : '비흡연'
            }
            if (key === 'height') {
                return `${this.profile[key]}cm`
            }

            return this.profile[key]
        },
        async onClickProfileItem(key) {
            // 모아찍기(1차지역->2차지역, 학부->대학->학과) 필요
            if (key === 'age') return

            if (key === 'intro') {
                this.$unregisterBackHandler()
                this.$stackRouter.push({
                    name: 'EditProfileText',
                    props: {
                        type: key,
                        section: this.section.title,
                    },
                })

                return
            }

            if (key === 'state') {
                this.$profile.editState(key)
            } else if (key === 'health_status') {
                this.$stackRouter.push({
                    name: 'EditProfileDetailHealth',
                    props: {
                        type: key,
                    },
                })
            } else {
                // 모아찍기 불필요
                const item = await this.$modal.custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: key,
                        profile: this.profile,
                        icon: 'close',
                        headerTitle: this.$translate('PERSONAL'),
                    },
                })
                if (!item || item.length === 0) return

                // profile_options을 통해 값을 업데이트 해야 하는 요소들과 그렇지 않은 것으로 분기
                const needProfileOptions = this.$profile.needProfileOption(key)
                const multipleChoice = this.$profile.multipleChoice(key)

                if (needProfileOptions) {
                    // 다중 선택이 가능한 옵션들과 그렇지 않은 것으로 분기
                    if (multipleChoice) {
                        this.$set(this.profile, key, item.map(i => i.name).join(', '))
                    } else {
                        this.$set(this.profile, key, item.name)
                    }

                    this.$profile.updateProfileOptions(key, item)
                } else {
                    if (key === 'smoking') {
                        this.$set(this.profile, key, item.name === '흡연')
                        return
                    }
                    if (key === 'marry') {
                        this.$set(this.profile, key, item.id)
                        return
                    }
                    this.$set(this.profile, key, item)
                }
            }
        },
    },
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        profileUpdateRequest() {
            return this.$store.getters.profileUpdateRequests
        },
        profileIntro() {
            if (!this.profile.intro) return false

            return this.profile.intro.split(/\n/).join('<br>')
        },
        placeholderIntro() {
            return '입력해주세요'
        },
        personal() {
            const profile = this.$store.getters.profile || {}
            const personalArr = []
            Object.keys(this.section.column).forEach(key => {
                const res = {}
                const value = this.section.column[key]
                if (value === 'age') {
                    res[value] = this.$options.filters.asAge(profile.birthday)
                } else {
                    res[value] = profile[value]
                }
                res.key = value

                // pur
                if (value === 'state') {
                    res.pur = !!this.profileUpdateRequest.state_id
                } else {
                    res.pur = ![null, undefined].includes(this.profileUpdateRequest[value])
                }

                personalArr.push(res)
            })

            return personalArr
        },
    },
    mounted() {},
}
</script>
<style scoped></style>
